<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <strong>Editar Usuarios</strong>
          </CCardHeader>
          <CCardBody>
            <CForm validate @submit="actualizarUsuario">
                    <div v-for="(valor, campo) in form" :key="campo">
                      <template v-if="campo == 'activo'">
                        <CSelect
                            :label="capitalizarCadaPalabra(campo)"
                            :options="status_opcion"
                            required
                            was-validated
                            :value.sync="form[campo]"
                        /> 
                      </template>
                      <template v-else-if="campo == 'firma_envio'">
                       <div class="form-group">
                          <label for="">
                            {{capitalizarCadaPalabra(campo)}}
                          </label>
                          <textarea
                            class="form-control"
                            rows="5"
                            v-model="form[campo]"
                          ></textarea>
                      </div>
                      </template>
                      <template v-else-if="validateShowNumeric(campo)">
                        <CInput
                          :label="capitalizarCadaPalabra(campo)"
                          autocomplete="off"
                          minlength="1"
                          required
                          type="number"
                          was-validated
                          v-model="form[campo]"
                        />
                      </template>
                      <template v-else>
                        <CInput
                          :label="capitalizarCadaPalabra(campo)"
                          autocomplete="off"
                          minlength="1"
                          required
                          was-validated
                          v-model="form[campo]"
                        />
                      </template>
                    </div>
                    <hr>
              <CRow>
                <CCol col="6">
                  <router-link to="/mailing/usuarios">
                    <CButton color="primary" block>Atras</CButton>
                  </router-link>
                </CCol>
                <CCol col="6">
                  <CButton
                    color="success"
                    type="submit"
                    block
                    >Actualizar</CButton
                  >
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <!--<CCardFooter>
          
        </CCardFooter>-->
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            Datos Guardado Correctamente.
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CContainer>
</template>

<script>
export default {
  name: "EditarUsuario",
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      form: {
          iduser:'',
          de:'',	
          de_nombre:'',	
          id_ruta:'',
          smtp_host:'',	
          smtp_puerto:'',	
          smtp_user:'',	
          smtp_clave:'',	
          firma_envio:'',
          limite_archivos:'',	
          limite_megas_archivos:'',	
          maximo_boletines:'',
          maximo_grupos:'',
          smtp_user2:'',	
          smtp_clave2:'',	
          smtp_user3:'',	
          smtp_clave3:'',	
          smtp_user4:'',	
          smtp_clave4:'',	
          smtp_user5:'',	
          smtp_clave5:'',	
          activo:'si',
      },
      roles:[],
      status_opcion:[{value:'si',label:'Activo'},{value:'no',label:'Inactivo'}],
    };
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    obtenerUsuarioPorId() {
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get(this.$httpUrlMail+"/mail/usuarios/" + id,{withCredentials:false})
        .then((response) => {
          let data = response.data;
          this.form.iduser = data.iduser;
          this.form.de = data.de;
          this.form.de_nombre = data.de_nombre;
          this.form.id_ruta = data.id_ruta;
          this.form.smtp_host = data.smtp_host;
          this.form.smtp_puerto = data.smtp_puerto;
          this.form.smtp_user = data.smtp_user;
          this.form.smtp_clave = data.smtp_clave;
          this.form.firma_envio = data.firma_envio;
          this.form.limite_archivos = data.limite_archivos;
          this.form.limite_megas_archivos = data.limite_megas_archivos;
          this.form.maximo_boletines = data.maximo_boletines;
          this.form.maximo_grupos = data.maximo_grupos;
          this.form.smtp_user2 = data.smtp_user2;
          this.form.smtp_clave2 = data.smtp_clave2;
          this.form.smtp_user3 = data.smtp_user3;
          this.form.smtp_clave3 = data.smtp_clave3;
          this.form.smtp_user4 = data.smtp_user4;
          this.form.smtp_clave4 = data.smtp_clave4;
          this.form.smtp_user5 = data.smtp_user5;
          this.form.smtp_clave5 = data.smtp_clave5;
          this.form.activo = data.activo;
        })
        .catch((e) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    actualizarUsuario(e) {
      e.preventDefault();
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .put(this.$httpUrlMail+"/mail/usuarios/" + id, {
          ...this.form
        },{withCredentials:false})
        .then((response) => {
          if (response.data == "OK") {
            this.mostrarNotificacion++;
            e.target.reset();
            setTimeout(() => {
              this.$router.push({path: '/mailing/usuarios'})
            }, 3000);
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
          }
        })
        .catch((error) => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    generarToken() {
      this.form.token_integracion = this.token();
    },
    random() {
      return Math.random().toString(36).substr(2); // Eliminar `0.`
    },
    token() {
      return this.random() + this.random(); // Para hacer el token más largo
    },
    capitalizarCadaPalabra(frase) {
      // Dividir la frase en palabras
      frase = frase.replace(/_/g, ' ');
      let palabras = frase.split(' ');
      // Capitalizar la primera letra de cada palabra
      let palabrasCapitalizadas = palabras.map(function(palabra) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
      });
      // Unir las palabras capitalizadas de nuevo en una frase
      let fraseCapitalizada = palabrasCapitalizadas.join(' ');
      return fraseCapitalizada;
    },
    validateShowNumeric(campo){
       let columnasExcluidas = ['iduser','id_ruta','limite_archivos','limite_megas_archivos','maximo_boletines','maximo_grupos'];
        if (columnasExcluidas.includes(campo)) {
            return true;
        }else{
            return false;
        }
    }
  },
  mounted() {
    setTimeout(() => {
      this.obtenerUsuarioPorId();
    }, 100);
  },
};
</script>
